<template>
  <div class="card-restaurante">
    <div class="container-info">
      <p class="text-body-1 mb-0 title-info">{{ item.title }}</p>
    </div>
    <div class="container-descripcion">
      <p class="text-body-1 mb-0 text-descripcion">{{ item.descripcion }}</p>
    </div>
    <div class="container-actions">
      <v-btn
        class="mr-4"
        fab
        small
        :to="{ name: 'ResponderEncuesta', params: { id: item.id } }"
        color="grayLight"
      >
        <v-icon color="black" small>mdi-hamburger</v-icon>
      </v-btn>
      <v-btn
        class="mr-4"
        fab
        small
        :to="{ name: 'InformacionEncuesta', params: { id: item.id } }"
        color="grayLight"
      >
        <v-icon color="black" small> mdi-pencil </v-icon>
      </v-btn>
      <v-btn
        fab
        small
        @click="openEliminarEncuesta(item)"
        color="grayLight"
      >
        <v-icon color="black" small>mdi-delete</v-icon>
      </v-btn>
    </div>
    <!-- modal eliminar -->
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Estas seguro que deseas eliminar {{ nameDelete }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="errorColor" text @click="closeDeleteModal"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              text
              @click="deleteEncuesta()"
              :loading="loading"
              >Confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- modal eliminar -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      loading: false,
      dialogDelete: false,
      nameDelete: "",
      idItemEliminar: 0,
    };
  },
  methods: {
     ...mapActions("moduloEncuestas", ["eliminarEncuesta"]),
    async openEliminarEncuesta(item) {
      this.dialogDelete = true;
      this.idItemEliminar = item.id;
      this.nameDelete = item.nombre;
    },
    closeDeleteModal() {
      this.dialogDelete = false;
      this.nameDelete = "";
      this.idItemEliminar = 0;
    },
    async deleteEncuesta() {
      this.loading = true;
      const result = await this.eliminarEncuesta({
        id: this.idItemEliminar
      });
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
      this.closeDeleteModal();
    },
  },
};
</script>