<template>
  <v-container>
    <div class="container-global">
      <!-- <v-row>
        <v-col col="12" sm="12" md="8">
          <h2 class="mb-2">Encuestas</h2>
          <p class="primary--text font-weight-bold">Filtra las encuestas por restaurante</p>
          <v-select
            :items="listRestaurantes"
            :item-text="'nombre'"
            :item-value="'id'"
            v-model="filterRestaurante"
            :rules="restauranteRules"
            name="listRestaurantes"
            label="Selecciona un restaurante"
            color="primary"
            required
            class="mb-4"
            v-on:change="filterEncuestas()"
          ></v-select>
          <ListEncuestas :encuestas="encuestas" :idRestaurante="filterRestaurante" v-if="encuestas"></ListEncuestas>
        </v-col>
      </v-row> -->
      <v-skeleton-loader
        v-show="showSkeInfo"
        class="mx-auto"
        max-width="100%"
        max-height="500px"
        type="table-heading, actions, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line, list-item-avatar-three-line"
      ></v-skeleton-loader>
      <template v-if="!showSkeInfo">
        <div class="d-flex align-center justify-space-between mb-12">
          <h2 class="mb-0">Mis encuestas</h2>
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              class="white--text pr-8 pl-8 btn-margin"
              :to="{ name: 'CrearEncuesta' }"
              >CREAR NUEVA ENCUESTA</v-btn
            >
          </div>
        </div>
        <div class="d-flex flex-column align-center" v-if="listEncuestas.length == 0">
          <h3>No hay encuestas disponibles</h3>
          <v-row justify="center" class="mt-12">
            <v-col col="12" sm="12" md="6" lg="8">
              <img
                width="100%"
                :src="require('@/assets/crear-menu.png')"
                alt="icon edit"
              />
            </v-col>
          </v-row>
        </div>
        <ListEncuestas
          :listEncuesta="listEncuestas"
          v-if="listEncuestas.length > 0"
        ></ListEncuestas>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ListEncuestas from "@/components/encuesta/ListEncuesta";
export default {
  data() {
    return {
      filterRestaurante: "",
      restauranteRules: [(v) => !!v || "El restaurante es requerido"],
      showSkeInfo: true,
    };
  },
  computed: {
    ...mapState("moduloEncuestas", ["listEncuestas"]),
  },
  created() {
    this.obtieneRestaurantes();
    this.obtieneEncuestas();
    this.showSkeInfo = false;
  },
  components: {
    ListEncuestas,
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["obtieneRestaurantes"]),
    ...mapActions("moduloEncuestas", ["obtieneEncuestas"]),
    // async filterEncuestas() {
    //   const result = await this.obtieneEncuestasFilter(this.filterRestaurante);
    //   if (result.res) {
    //     this.$swal({
    //       showConfirmButton: false,
    //       timer: 3000,
    //       icon: "success",
    //       timerProgressBar: true,
    //       title: "Se filtraron las encuestas correctamente.",
    //     });
    //   } else {
    //     this.$swal({
    //       showConfirmButton: false,
    //       timer: 3000,
    //       icon: "error",
    //       timerProgressBar: true,
    //       title: result.error,
    //     });
    //   }
    // },
  },
};
</script>
<style lang="scss">
.list-encuesta {
  list-style: none;
  padding: 0 !important;
  margin: 0;
  border-top: 1px solid #000000;
  li {
    padding: 12px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }
  .card-restaurante {
    display: flex;
    align-items: center;
    .container-info {
      flex-basis: 40%;
      display: flex;
      align-items: center;

      .title-info {
        margin-bottom: 0;
        max-width: 60%;
      }
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 100%;
        margin-bottom: 16px;
      }
    }
    .container-descripcion {
      flex-basis: 30%;
      display: flex;
      align-items: center;
      .text-descripcion {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
      }
    }
    .container-actions {
      flex-basis: 30%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn-margin {
        margin-right: 25px;
        //movil
        @media (max-width: 480px) {
          margin-bottom: 16px;
          margin-right: 0;
        }
      }
      @media (max-width: 1024px) {
        flex-basis: 100%;
        padding-left: 24px;
      }
      //movil
      @media (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>