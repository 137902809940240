<template>
  <ul
    class="list-encuesta"
  >
    <li v-for="(item, index) in listEncuesta" :key="index">
        <CardEncuesta :item="item"></CardEncuesta>
    </li>
  </ul>
</template>
<script>
import CardEncuesta from "@/components/encuesta/CardEncuesta";
export default {
    props:{
        listEncuesta: Array
    },
    components:{
      CardEncuesta
    }
};
</script>